import {useCallback, useEffect, useState} from 'react';

import fp from 'lodash/fp';

import {useApi} from '@arborian/narrf';

import {useDialog} from 'ccm/components/dialogs';
import OrdersToolDialog from '../ticket/OrdersToolDialog';
import * as f from './signatureFields';

export const usePageState = (formState, page, pageNumber, canvas) => {
    const api = useApi();
    const backgroundImgSrc = fp.get('attributes.imgSrc', page);
    const fields = fp.get('attributes.fields', page);
    const ordersDialog = useDialog(OrdersToolDialog);
    const [changed, setChanged] = useState(false);

    // Register canvas
    const registerCanvas = formState.registerCanvas;
    useEffect(() => {
        if (canvas) {
            registerCanvas(pageNumber, canvas);
        }
    }, [registerCanvas, canvas, pageNumber]);

    // Set canvas background image & scale
    useEffect(() => {
        if (!canvas || !backgroundImgSrc) return;
        _setCanvasBackground(canvas, backgroundImgSrc);
    }, [canvas, backgroundImgSrc]);

    // Add fields to canvas
    useEffect(
        () => {
            if (!canvas) return;
            _addFieldsToCanvas(
                fp.cloneDeep(fields),
                canvas,
                formState.setComplete,
                ordersDialog,
            );
            return () => {
                canvas.remove(...canvas.getObjects());
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [pageNumber, fields, canvas, formState.setComplete],
    );

    const checkComplete = formState.checkComplete;
    const setComplete = formState.setComplete;
    const handleClick = useCallback(
        async ev => {
            const element = canvas.findTarget(ev);
            if (!element) return;
            const field = element.field;
            ev.element = element;
            ev.canvas = canvas;
            await field.click(ev);
            if (field.data.filled) {
                checkComplete();
            } else {
                setComplete(false);
            }
            setChanged(true);
            canvas.requestRenderAll();
        },
        [canvas, checkComplete, setComplete, setChanged],
    );

    const handleKeyDown = useCallback(
        ev => {
            if (!canvas) return;
            const deleteKey = 8;
            if (ev.keyCode === deleteKey) {
                const objs = canvas.getActiveObjects();
                canvas.remove(...objs);
            }
            setChanged(true);
        },
        [canvas, setChanged],
    );

    const handleDrop = useCallback(
        ev => {
            ev.preventDefault();
            const data = JSON.parse(
                ev.dataTransfer.getData('application/json'),
            );
            _addField(ev, canvas, data);
            setChanged(true);
        },
        [canvas, setChanged],
    );

    const saveCanvas = useCallback(() => {
        if (changed) {
            _saveCanvas(api, canvas, page);
            setChanged(false);
        }
    }, [api, canvas, page, changed, setChanged]);

    return {
        pageNumber,
        handleClick,
        handleKeyDown,
        handleDrop,
        saveCanvas,
    };
};

function _setCanvasBackground(canvas, imgSrc) {
    return new Promise((resolve, reject) => {
        if (!canvas.lowerCanvasEl) {
            resolve(canvas);
            return;
        }
        const scaleCanvas = async (img, isError) => {
            if (!canvas.lowerCanvasEl) return;
            // Scale canvas
            const imgWidth = img.width;
            const imgHeight = img.height;
            const canvasWidth = canvas.getWidth();
            if (imgWidth > canvasWidth) {
                let zoomOutValue = canvasWidth / imgWidth;
                canvas.setZoom(zoomOutValue);
            }
            canvas.setHeight(canvas.getZoom() * imgHeight);
            canvas.calcOffset();
            canvas.requestRenderAll();
            resolve(canvas);
        };
        canvas.setBackgroundImage(imgSrc, scaleCanvas, {
            crossOrigin: 'Anonymous',
        });
    });
}

async function _addFieldsToCanvas(
    fieldDatas,
    canvas,
    setComplete,
    ordersDialog,
) {
    // Add field annotations
    const fields = fieldDatas.map(fldData => {
        const fld = f.fieldFromFieldData(fldData, {ordersDialog});
        if (fld && !fld.data.filled) {
            setComplete(false);
        }
        return fld;
    });
    // Ensure canvas has no objects
    // canvas.remove(...canvas.getObjects());
    // Add annos to canvas
    await Promise.all(fields.map(anno => anno.draw(canvas)));
    canvas.requestRenderAll();
    return canvas;
}

async function _addField(ev, canvas, data) {
    const pointer = canvas.getPointer(ev);
    const {offset, ...fieldData} = data;
    const field = fp.merge(fieldData, {
        coords: {
            left: pointer.x,
            top: pointer.y,
            scaleX: 1,
            scaleY: 1,
        },
        editable: false,
        required: true,
        filled: false,
        value: null,
    });
    const fld = f.fieldFromFieldData(field, canvas);
    const anno = await fld.draw(canvas);
    anno.set({
        left: pointer.x - anno.width * offset.x,
        top: pointer.y - anno.height * offset.y,
    });
    return anno;
}

async function _saveCanvas(api, canvas, page) {
    const fields = fp.pipe(
        fp.map(el => el.field.toJSON(el)),
        fp.map(d => [d.id, d]),
        fp.fromPairs,
        fp.values,
    )(canvas.getObjects());
    const c = await new Promise(resolve =>
        canvas.clone(resolve, {
            enableRetinaScaling: true,
        }),
    );
    const blob = await canvasToFullsizeBlob(c);
    const pageData = fp.set('attributes.fields', fields, page);
    console.log('pageState.handleSave', {
        fields,
        pageData,
    });

    if (page.links.content) {
        let body = new FormData();
        body.append('file', blob);
        body.append('data', JSON.stringify({data: pageData}));
        await api.fetchJsonApi(page.links.content, {
            method: 'PUT',
            body,
        });
    } else {
        await api.fetchJsonApi(page.links.self, {
            method: 'PUT',
            json: {
                data: pageData,
            },
        });
    }
}

function canvasToFullsizeBlob(canvas) {
    canvas.setWidth(canvas.backgroundImage.width);
    canvas.setHeight(canvas.backgroundImage.height);
    canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    canvas.calcOffset();
    canvas.discardActiveObject();
    canvas.renderAll();
    return new Promise(resolve => {
        canvas.toCanvasElement().toBlob(resolve, 'image/png');
    });
}
